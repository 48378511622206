import React from "react";
import { Card } from "antd";
import CompleteIcon from "../assets/icons/fluent_payment-28-regular.webp"; // Make sure this path is correct

const PaySuccess: React.FC = () => {
  return (
          <div className="flex justify-center items-center" style={{ height: '80vh' }}>
      <Card className="w-full max-w-md text-center border border-gray-200 p-10">
        <div className="flex justify-center mb-4">
          <img src={CompleteIcon} alt="Complete Icon" />
        </div>
        <h1 className="text-2xl mb-2">Your payment complete</h1>
        <p className="text-gray-300">The confirmation email will be sent later.</p>
      </Card>
    </div>
  );
};

export default PaySuccess;