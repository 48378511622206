import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import PaySuccess from "./views/PaySuccess";
import PayFail from "./views/PayFail";
import PayCancel from "./views/Cancel";
import Payment from "./views/Payment";
import HeaderComponent from "./components/header";

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path="/:refid" element={<Payment />} />,
    <Route path="/success/:refid" element={<PaySuccess />} />,
    <Route path="/fail/:refid" element={<PayFail />} />,
    <Route path="/cancel/:refid" element={<PayCancel />} />,
    <Route path="/payment/:refid" element={<Payment />} />,
    <Route path="*" element={<Payment />} />, 

  ])
);

const App: React.FC = () => {
  return (
    <>
      <HeaderComponent />
      <div className="pr-10 pl-10">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;