/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Radio, Button, Card, Row, Col } from "antd";
import "tailwindcss/tailwind.css";
import promptpayLogo from "../assets/payment/promptpay.webp";
import GroupPaymentLogo from "../assets/payment/cc/Payment.webp";
import { useParams } from "react-router-dom";
import { format } from "numerable";
import "./Payment.css";
export interface PaymentForm {
  secureHash: string | number | readonly string[] | undefined;
  merchantId: string;
  name: string;
  payType: string;
  payMethod: string;
  amount: string;
  orderRef: string;
  currCode: string;
  successUrl: string;
  failUrl: string;
  cancelUrl: string;
  lang: string;
  detail: string;
  redirect: string;
  orderRef1: string;
  orderRef2: string;
  orderRef3: string;
  orderRef4: string;
  orderRef5: string;
}
const Payment: React.FC = () => {
  const [paymentSelect, setPaymentSelect] = React.useState<number | undefined>(
    undefined
  );

  const [cart, setCart] = React.useState<PaymentForm | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  const { refid } = useParams<{ refid: string }>();
  const fetchRef = () => {
    const urls =
      process.env.REACT_APP_RUN_MODE === "production"
        ? [`https://paylink-api-prod.saintlouis.or.th/checkout/${refid}`]
        : [`https://paylink-api.saintlouis.or.th/checkout/${refid}`];

    Promise.all(
      urls.map((url) =>
        fetch(url, {
          method: "GET",
        }).then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
      )
    )
      .then((data) => {
        setCart(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    if (refid) {
      fetchRef();
    }
  }, [refid]);
  if (!refid) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "#f0f2f5",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "48px",
            color: "#ff4d4f",
            marginBottom: "16px",
          }}
        >
          Oops!
        </span>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "#595959",
          }}
        >
          The content you're looking for cannot be found.
        </span>
      </div>
    );
  }

  const handleSelectPayment = (no: number) => {
    setPaymentSelect(no);
  };

  const handleNav = () => {
    if (paymentSelect === 1) {
      return "PROMPTPAY";
    }
    if (paymentSelect === 2) {
      return "CC";
    }
    if (paymentSelect === 3) {
      return "Alipay";
    }
    if (paymentSelect === 4) {
      return "wechat";
    }
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "#f0f2f5",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  }
  if (!cart && !loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "#f0f2f5",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "48px",
            color: "#ff4d4f",
            marginBottom: "16px",
          }}
        >
          Oops!
        </span>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            color: "#595959",
          }}
        >
          The content you're looking for cannot be found.
        </span>
      </div>
    );
  }

  return (
    <div className="max-w-8xl mx-auto">
      <form
        name="payFormCcard"
        method="post"
        action={
          process.env.REACT_APP_RUN_MODE === "production"
            ? "https://ipay.bangkokbank.com/b2c/eng/payment/payForm.jsp"
            : "https://psipay.bangkokbank.com/b2c/eng/payment/payForm.jsp"
        }
      >
        <input type="hidden" name="merchantId" value={cart?.merchantId} />
        <input type="hidden" name="payType" value={cart?.payType} />
        <input type="hidden" name="payMethod" value={`${handleNav()}`} />
        <input type="hidden" name="amount" value={cart?.amount} />
        <input type="hidden" name="orderRef" value={refid} />
        <input type="hidden" name="currCode" value="764" />
        <input type="hidden" name="successUrl" value={`${cart?.successUrl}`} />
        <input type="hidden" name="failUrl" value={`${cart?.failUrl}`} />
        <input type="hidden" name="cancelUrl" value={`${cart?.cancelUrl}`} />
        <input
          type="hidden"
          name="lang"
          value={cart?.lang === "th" ? "T" : "E"}
        />
        <input type="hidden" name="remark" value="-" />
        <input type="hidden" name="redirect" value="30" />
        <input type="hidden" name="orderRef1" value={cart?.orderRef1} />
        <input type="hidden" name="orderRef2" value={cart?.orderRef2} />
        <input type="hidden" name="orderRef3" value={cart?.orderRef3} />
        <input type="hidden" name="secureHash" value={cart?.secureHash} />
        <Card style={{ border: "none" }}>
          <Row gutter={[16, 16]} className="flex-col-reverse lg:flex-row">
            <Col xs={24} lg={12}>
              <h1 className="text-xl font-bold mb-10">
                Choose your payment method
              </h1>
              <Radio.Group className="w-full mb-6">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className="border border-gray-300 rounded-md p-5 mb-1 shadow-md flex justify-between items-center">
                      <Radio
                        onChange={(e: any) =>
                          handleSelectPayment(e.target.value)
                        }
                        value={1}
                        style={{ height: "2vh" }}
                        className="w-full"
                      >
                        <div className="flex justify-between items-center w-full">
                          <span>PromptPay</span>
                        </div>
                      </Radio>
                      <img
                        style={{ height: "35px" }}
                        width={"108px"}
                        src={promptpayLogo}
                        alt="PromptPay"
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="border border-gray-300 rounded-md p-5 mb-1 shadow-md flex justify-between items-center overflow-auto">
                      <Radio
                        onChange={(e: any) =>
                          handleSelectPayment(e.target.value)
                        }
                        value={2}
                        style={{ height: "2vh" }}
                        className="w-full"
                      >
                        <div className="flex justify-between items-center w-full">
                          <span>Credit Card</span>
                        </div>
                      </Radio>
                      <img
                        style={{ width: "300px", height: "35px" }}
                        src={GroupPaymentLogo}
                        alt="Credit Card"
                      />
                    </div>
                  </Col>
                </Row>
              </Radio.Group>
              <Button
                htmlType="submit"
                type="primary"
                onClick={handleNav}
                danger={!!paymentSelect}
                className="w-full mt-4 bg-red-500 border-none h-12 text-lg"
                disabled={!paymentSelect}
              >
                Check out
              </Button>
            </Col>
            <Col xs={24} lg={12}>
              <div className="text-sm ">
                <h2 className="text-xl font-bold mb-10">Summary</h2>
                <div className="bg-blue-100 pl-2 pt-2 pb-2 mb-1 ">
                  <span>Detail</span>
                </div>
                <div
                  style={{ display: cart?.orderRef1 ? "" : "none" }}
                  className="flex justify-between pl-2 mb-3"
                >
                  <span>Reference No #{1}:</span>
                  <span>{cart?.orderRef1}</span>
                </div>
                <div
                  style={{ display: cart?.orderRef2 ? "" : "none" }}
                  className="flex justify-between pl-2 mb-3"
                >
                  <span>Reference No #{2}:</span>
                  <span>{cart?.orderRef2}</span>
                </div>
                <div
                  style={{ display: cart?.orderRef3 ? "" : "none" }}
                  className="flex justify-between pl-2 mb-3"
                >
                  <span>Reference No #{3}:</span>
                  <span>{cart?.orderRef3}</span>
                </div>
                <div className="flex justify-between border-b border-gray-300 pb-2 pl-2 mb-3">
                  <span>Amount:</span>
                  <span>{format(cart?.amount, "0,0.00") || 0} THB</span>
                </div>
                <div className="flex justify-between pl-2 mb-3">
                  <span>Detail:</span>
                  <span className="text-gray-400 pl-2 mb-3">
                    {cart?.detail}
                  </span>
                </div>
                <div className="pl-2 flex justify-between mb-1 border-b border-gray-300 pb-2">
                  <span>Customer name:</span>
                  <span>{cart?.name}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </form>
    </div>
  );
};

export default Payment;
