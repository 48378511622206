import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import SLHLogo from "../assets/photo/SLSH-Logo.webp";

const { Header } = Layout;

const HeaderComponent: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headerStyle = isMobile
    ? { borderBottom: "6px solid #AF9258" }
    : {
        borderBottom: "6px solid transparent",
        borderImage: "linear-gradient(to right, #0F4C81 50%, #AF9258 50%) 1",
      };

  return (
    <Header
      className="bg-white border-b-4"
      style={headerStyle}
    >
      <img
        src={SLHLogo}
        alt="Saint Louis Hospital"
        className="inline-block h-15 w-55"
      />
    </Header>
  );
};

export default HeaderComponent;