import React from "react";
import { Card, Button } from "antd";
import FailIcon from "../assets/icons/fluent_payment-28-regular1.webp";
import { useNavigate, useParams } from "react-router-dom";

const PayFail: React.FC = () => {
  const { refid } = useParams<{ refid: string }>();
  const navigate = useNavigate();
  console.log(refid);
  return (
    <div
      className="flex justify-center items-center"
      style={{ height: "80vh" }}
    >
      <Card className="w-full max-w-xl text-center border-gray-200">
        <div className="flex justify-center mb-4">
          <img src={FailIcon} alt="Fail Icon" />
        </div>
        <h1 className="text-2xl mb-2">Payment Error!</h1>
        <p className="text-gray-300">
          Sorry, Your transaction has failed. Please try again.
        </p>
        <Button onClick={() => navigate("/" + refid)} className="mt-5">
          Try Again
        </Button>
      </Card>
    </div>
  );
};

export default PayFail;
